// Colors
$theme-primary: #446084;
$theme-secondary: #666666;

// Assign colors to Bootstrap default
$primary: $theme-primary;
$secondary: $theme-secondary;

// Other customizations
$breadcrumb-bg: transparent;
$breadcrumb-divider: '»';
$breadcrumb-padding-x: 0;
$navbar-padding-x: 0;
$navbar-item-margin: 20px;
$navbar-dark-color: #fff;
$navbar-dark-active-color: $primary;

$optionSelectedBorder: $theme-primary !default;
$optionSelectedBackground: #f1f1f1 !default;
$optionSelectedColor: inherit !default;
$optionBackground: #f1f1f1 !default;
$optionRadius: 3px !default;
$optionColor: #555 !default;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap";

.jumbotron {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
}
.add-to-cart__price_wrapper {
  font-weight: bold;
  margin-bottom: 0;
}
.add-to-cart__stock_wrapper {
  color: $gray-600;
  margin-bottom: 0;
  font-weight: normal;
}
.product-top-container {
  margin: 0 0 2rem 0;
}
.product-image {
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0;
  overflow: hidden;
}
.product-image__img {
  width: 100%;
  max-height: 400px;
  transition: max-height 0.5s;
  object-fit: cover;
}
.product-details {
  padding: 1rem 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.product-add-to-cart {
  margin-top: auto;
}
.add-to-cart__submit {
  //text-align: right;
  //align-self: flex-end;
}
@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
  .product-image {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .product-details {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.breadcrumb-item {
  color: $gray-300;
  a {
    color: $gray-600;
    &:hover, &:focus {
      color: $primary;
    }
  }
}
.breadcrumb-item.active {
  a, a:focus, a:hover {
    color: $primary;
    cursor: default;
    text-decoration: none;
  }
}

.category-product__card {
  margin-bottom: 2rem;
  color: $body-color;
  &:hover, &:focus {
    text-decoration: none;
    box-shadow: 0 0 5px $primary;

    .card-text {
      color: $body-color;
    }
  }
}

// Cart
.cart__total_items {
  font-weight: normal;
  color: $primary;
  font-size: 1rem;
}
.cart-title__checkout {
  font-size: 1rem; // Fixes issue with alignment inside heading
}
.cart-items {
  padding: 1rem 0;
}
.cart-item {
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //background: #fff;
  //padding-bottom: 1rem;
  padding-top: 1rem;
  //margin-bottom: 2rem;
  //display: flex;
  overflow: hidden;
  //flex-wrap: wrap;

  border-bottom: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  &:first-of-type {
    border-top: 1px solid $gray-200;
    padding-top: 1rem;
    border-radius: 5px 5px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }

  background: #fff;
  transition: background-color 200ms ease-in-out;
  &:hover, &:focus-within {
    background: hsla(193, 100%, 90%, 0.2);
  }

  > div {
    padding-bottom: 1rem;
  }
}
.cart-item__imagecontainer {
  display: flex;
  flex-direction: column;
}
.cart-item__image {
  flex: 1;
  width: 7rem;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.cart-item__product {
  //margin: 1rem;
  display: flex;
  flex-direction: column;
}

.cart-item__name {
  font-size: 1rem;
  font-weight: normal;
  color: $primary;
  a {
    color: $primary;
  }
}
.cart-item__actions {
  margin-top: auto;
  text-align: left;
}
.cart-item__numbers {
  //margin: 1rem 1rem 1rem auto;
  //text-align: right;
  span {
    transition: opacity 0.5s ease-in-out;
  }
}
.cart-item__numbers__loading {
  //span {
  //  opacity: 0.3;
  //}
}
.c-checkout-wrapper {
  > * {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}
.commerce-loader {
  position: relative;
  > * {
    opacity: 0.5;
  }
  &:after {
    content: " ";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border-width: 6px;
    border-color: $primary transparent $primary transparent;
    animation: spinner 1.2s linear infinite;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-style: solid;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}


.cart-item__single_price {
  //margin-left: 2rem;
  color: $gray-700;
}
.cart-item__subtotal_old {
  //background: linear-gradient(to left top, transparent 45%, $red 46.5%, $red 50.5%, transparent 52.25%);
  background: linear-gradient(to left top, transparent 45%, $primary 46.5%, $primary 50.5%, transparent 52.25%);
  padding: 0 0.34rem;
  color: $gray-700;
  font-size: $font-size-sm;
}
.cart-item__quantity_field {
  width: 3rem;
  text-align: center;
  border: 0;
  color: $primary;
  border-bottom: 1px dotted $primary;
  padding-left: 0.67rem;
}
.cart-item__quantity_indicator {
  color: $gray-700;
}
.cart-item__description {
  font-size: $font-size-sm;
  color: $gray-700;
  margin-bottom: 0;
}
.cart-item__discount {
  //font-size: $font-size-sm;
  color: $primary;
}
.cart-item__tax {
  //font-size: $font-size-sm;
  color: $gray-700;
}
.cart-item__tax_incl {
  font-size: $font-size-sm;
  color: $gray-700;
}
.cart-item__subtotal {
  font-weight: bold;
  //font-size: $font-size-sm;
  color: $primary;
}
.cart-item__subtotal_ex_tax {
  font-weight: normal;
}
.cart-item__total_incl_tax {
  font-weight: bold;
  //font-size: $font-size-sm;
  color: $primary;
}
.cart-item__remove {
  background: none;
  border: 0;
  color: $red;
  font-size: $font-size-sm;
  margin-top: 0.34rem;
  padding: 0;
  opacity: 0;
  transition: opacity 0.25s ease-in;

  .cart-item:hover &, .cart-item:focus &, .cart-item:focus-within & {
    opacity: 1;
  }

  &:hover, &:focus {
    text-decoration: underline;
    cursor: pointer;
  }
}
@media (min-width: 768px) {

}

.cart-item__quantityfld {
  //display: flex; set via bootstrap's d-sm-flex utility for responsiveness
  justify-content: center;
}
.cart-item__quantityfld_plus,
.cart-item__quantityfld_minus {
  background-color: $white;
  color: $primary;
  //border: 1px solid $primary;
  border: 0;
  width: 1.5rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.cart-item__quantityfld_plus {
  border-radius: 0 3px 3px 0;
  //border-left: 0;
}
.cart-item__quantityfld_minus {
  border-radius: 3px 0 0 3px;
  //border-right: 0;
}
.cart-item__quantityfld_input {
  color: $primary;
  //background-color: theme-light;
  border: 1px solid $primary;
  border-radius: 3px;
  width: 3rem;
  text-align: center;

  // Hide the chrome number UI
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.cart-item__quantityfld_update.cart-item__quantityfld_update__hidden {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  padding: 0;
  visibility: hidden;
}


// Cart totals
.cart-totals {
  //border-top: 3px solid $gray-200;
  //padding-top: 1rem;
}
.c-cart-total-item, .c-cart-total-item-even {
  display: flex;
  align-items: center;
  line-height: 1.5em;
  justify-content: flex-end;
}
.c-cart-totals-label {
  color: $gray-700;
  text-align: right;
  flex: 1;
}
.c-cart-totals-value {
  text-align: right;
  min-width: 10em;
  color: $primary;
}
.c-cart-totals-simple-value {
  text-align: right;
}
.c-cart-totals__bold {
  font-weight: bold;
  color: $primary;
}
.c-cart-totals-savings {
  color: $primary;
}


.cart-callouts__column {
  padding: 3rem 0 0 1rem;
}
.cart-callouts__footer {
  padding: 1rem 0;

  .cart-callout {
    padding: 0.5rem;
  }
  .cart-callout__title {
    border-bottom: 1px solid #003c4d;
    padding-bottom: 0.25rem;
  }
}
.cart-callout {
  text-align: left;
  padding: 1rem 1rem 1rem 0.5rem;
  //border-right: 2px solid $gray-200;
  transition: padding 200ms ease-in-out;
  .cart-callout__desc {
    transition: color 200ms;
  }
  &:hover {
    padding-right: 1.25rem;
    padding-left: 0.25rem;
    .cart-callout__desc {
      color: $gray-900;
    }
  }
}

.cart-callout__title {
  color: $primary;
}
.cart-callout__desc {
  color: $gray-600;
  font-size: 0.9em;
  margin-bottom: 0;
}


// Styling cart/checkout elements with inherited classes
.c-cart-totals-wrapper {
  margin: 1rem 0;
}
.c-submit {
  text-align: right;
}
.c-cart-actions {
  margin-top: 2rem;
}
.c-cart-coupon-form {
  display: flex;
  flex-direction: column;

  label {
  }
  input[type=text] {
    display: block;
    font-size: 1em;
    text-align: center;
  }
  input[type=submit] {
    display: block;
    margin-top: 0.67rem;
    @extend .btn-secondary;
  }
}

#c-cart-coupon-label {
  opacity: 0;
  width: 0;
  height: 0;

  + label {
    font-weight: normal;
    &:hover, &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  // Keyboard focus on the input shows underline on label
  &:focus + label {
    text-decoration: underline;
  }

  // Hide the form by default
  + label + form {
    display: none;
  }

  // When the radio is checked, hide the checkbox (so it takes no more space) and the label, and show the form
  &:checked {
    display: none;
    + label {
      display: none;
    }
    + label + form {
      display: flex;
    }
  }
}

.c-button {
  @extend .btn;
  @extend .btn-primary;
}

// Checkout => shared bits
.c-step-wrapper {

}
.c-checkout-step {
  flex: 1;
}
@media (min-width: 768px) {
  .c-checkout-summary {
    padding-left: 2rem;
  }
}
.checkout__cart-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $gray-200;

  &:first-of-type {
    border-top: 1px solid $gray-200;
  }
}

// Method expanders
.c-method-gateway-form, .c-method-details {
  display: none;
  padding-top: 1em;
}
.c-method-wrapper {
  position: relative;
}

.c-method-radio {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
}
.c-payment-method-radio {
  top: 0.9rem;
  left: 0.9rem;
}
.c-method-radio:checked + .c-method-section {
  background: $gray-100;
  border-radius: .25rem;

  .c-method-gateway-form, .c-method-details {
    display: block;
  }
}
.c-method-section {
  margin: 1em 0 1em 0;
  padding: 0.67em;
  //background: $optionBackground;
  //border: 1px solid $optionColor;
  //border-radius: $optionRadius;
  > label {
    cursor: pointer;
    padding-left: 1.67em;
    margin-bottom: 0;
    width: 100%;

    &:hover {
      //color: $progressActiveColor;
    }
  }
}

.c-method-description {
  margin-bottom: 0;
}
.c-checkout-previous-address-list {
  display: flex;
  flex-wrap: wrap;
}
.c-new-shipping-address-wrapper, .c-new-billing-address-wrapper, .c-same-billing-address-wrapper {
  min-width: 100%;
}
.c-shipping-address-wrapper, .c-billing-address-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.c-shipping-address-section, .c-billing-address-section {
  border: 1px solid $gray-200;
  border-radius: 3px;
  flex: 1;
  margin-right: 1rem;
  margin-top: 0;
  padding: 1rem;
}


.minicart {
  position: relative;
}
.minicart__toggler {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0;
  left: 0;

  + label {
    margin-bottom: 0;
    cursor: pointer;
  }
  &:checked + label {

  }

  &:checked + label + .minicart__wrapper  {
    max-height: initial;
    visibility: visible;
    opacity: 1;
    height: auto;
    top: 100%;
  }

}
.minicart__wrapper {
  visibility: hidden;
  transition: all 0.5s;
  opacity: 0;
  height: 0;
  top: 0;
  margin-top: 0.25rem;

  position: absolute;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.125);
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 300px;
  z-index: 100;

  &:before {
    top: -1rem;
    right: 1rem;
    border: 0.5rem solid transparent;
    border-bottom: 0.5rem solid $gray-200;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-left: -0.5rem;
  }

}

.minicart__header {
  font-size: 0.9rem;
  padding: 1rem;
  background: $gray-200;
  border-radius: 3px 3px 0 0;
}
.minicart__total-label, .minicart__quantity-wrapper {
  color: $gray-700;
}
.minicart__total-value {
  color: $primary;
  font-weight: bold;
}

.minicart__checkout.btn-primary {
  border-color: $primary;
  background: $primary;
  color: theme-light;

  &:hover, &:focus {
    background: $black;
    color: $white;
  }
}
.minicart__cart.btn-outline-primary {
  border-color: $primary;
  color: $primary !important;

  &:hover, &:focus {
    background: $white;
    color: $primary !important;
  }
}

.minicart__items {
  list-style-type: none;
  padding: 1rem;
  margin-bottom: 0;
}
.minicart__item {
  display: flex;
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
}
.minicart__image {
  max-width: 3rem;
  max-height: 3rem;
  margin-right: 1rem;
  border-radius: 3px;
}
.minicart__content {
  flex: 1;
  overflow: hidden;
}
.minicart__name {
  color: $gray-900;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.minicart__price {
  color: $primary;
  font-size: 0.9rem;
  font-weight: bold;
}
.minicart__message {
  padding: 0.5rem;
  background: theme-light;
  color: $primary;
  border-radius: 3px;
  font-size: 0.9rem;

  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
}
.minicart__error {
  background: #ffc9ce;
}
.minicart__footer {
  padding: 1rem;
  background: $gray-200;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.minicart__extra {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
.minicart__extra-label {

}
.minicart__extra-value {
  color: $primary;
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 0.5rem;
}

// navbar
.navbar-nav {
  .nav-item {
    @media (min-width: 768px) {
      margin: 0 $navbar-item-margin;
    }
  }
  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    color: $navbar-dark-active-color;
    border-bottom: 1px solid $navbar-dark-active-color;
  }
}

// forms
.alert {
  p {
    margin-bottom: 0;
  }
}
#navbar-toggle-cbox:checked ~ .collapse {
  display:block;
}
#navbar-toggle-cbox {
  display: none;
}
.navbar-toggler {
  border-color: $primary;
  border: 1px solid transparent;
  border-radius: .25rem;
  -webkit-appearance: none;
}
@media (max-width: 991px) {
  .minicart {
    float: right;
  }
}
// Footer
#footer {
  border-top: 5px solid theme-light;
  background: $primary !important;
}
#footer a {
  color: $navbar-dark-color;
  text-decoration: none;
  @include hover-focus {
    color: $navbar-dark-hover-color;
  }

  &.disabled {
    color: $navbar-dark-disabled-color;
  }
}
// CSS tabs
section.tab_section {
  display: none;
  padding: 20px 0 0;
  border-top: 1px solid $gray-200;
}

input.tab_input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:focus + label.tab_label {
    text-decoration: underline;
  }
}

label.tab_label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

label.tab_label:hover {
  cursor: pointer;
}

input.tab_input:checked + label.tab_label {
  color: $theme-primary;
  border: 1px solid $gray-200;
  border-top: 2px solid $gray-200;
  border-bottom: 1px solid #fff;
}

#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4 {
  display: block;
}
// Section title
.section-title-container {
  margin-bottom: 1.5em;
}
.section-title {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.section-title b {
  display: block;
  flex: 1;
  height: 2px;
  background-color: $gray-200;
}
.section-title-center span {
  text-align: center;
  margin: 0 15px;
}

.c-checkout {
  .c-payment-method-options, .c-shipping-method-options {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .c-payment-method-issuers__container, .c-shipping-method-opts__container {
      display: none;
      border-left: 2px solid $optionSelectedBorder;
      border-right: 2px solid $optionSelectedBorder;
      border-bottom: 2px solid $optionSelectedBorder;
      margin-top: -2px;
      background: $optionBackground;
      padding: 0.5rem;
      border-radius: 0 0 $optionRadius $optionRadius;
    }
  }

  .c-payment-method-option__input:checked + .c-payment-method-option__label + .c-payment-method-issuers__container,
  .c-shipping-method-option__input:checked + .c-shipping-method-option__label + .c-shipping-method-opts__container {
    display: block;
  }

  .c-payment-method-issuers, .c-shipping-method-opts {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .c-payment-method-issuer, .c-payment-method-option,
  .c-shipping-method-opt, .c-shipping-method-option {
    position: relative;
    overflow: hidden;

    &:first-child {
      border-radius: $optionRadius $optionRadius 0 0;
    }
    &:last-child {
      border-radius: 0 0 $optionRadius $optionRadius;
    }
  }

  .c-payment-method-issuer__input, .c-payment-method-option__input,
  .c-shipping-method-opt__input, .c-shipping-method-option__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + {
      .c-payment-method-issuer__label, .c-payment-method-option__label,
      .c-shipping-method-opt__label, .c-shipping-method-option__label {
        font-weight: bold;
        border-color: $optionSelectedBorder;
        background: $optionSelectedBackground;
        color: $optionSelectedColor;
        border-radius: 3px;
      }
    }
  }

  .c-payment-method-issuer__label, .c-payment-method-option__label,
  .c-shipping-method-opt__label, .c-shipping-method-option__label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    border: 2px solid transparent;
    cursor: pointer;
    font-weight: normal;
    background-color: #fff;
    margin-bottom: 0;
  }
  .c-payment-method-issuer__image, .c-payment-method-option__image,
  .c-shipping-method-opt__image, .c-shipping-method-option__image {
    margin-right: 0.5rem;
  }

  .c-shipping-method-opt__label > span {
    flex: 1;
  }
  .c-shipping-method-opt__price {
    text-align: right;
  }
  .c-shipping-method-extra-opts__label {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .c-shipping-method-extra-opt, .c-shipping-method-time-opt {
    margin: 0.5rem 0;
    border-radius: $optionRadius;
    overflow: hidden;
  }

  .c-pickuplocation {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
  .c-pickuplocation__address {
    margin-top: 0;
  }
  .c-pickuplocation__openinghours {
    list-style-type: none;
    margin-left: auto;
    color: $optionColor;
  }
  .c-pickuplocation__openinghour {
    display: flex;
    > span {
      flex: 1 0 50%;
      padding: 0 0.5em;
      font-size: 0.9em;
    }
  }
}

.StripeElement {
  width: 100%;
  padding: 0.5em;
  margin: 0 0 0.5em;
  display: block;

  border: 1px solid transparent;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.c-method-gateway-form {
  label {
    display: block;
    width: 100%;
  }
  input, select {
    margin-bottom: 1em;
  }
  .form-row {
    margin-left: 0;
    margin-right: 0;
  }
}